import React, {useContext, useState, useEffect} from "react";
import classNames from "classnames";
import {CSSTransition} from 'react-transition-group';
import {observer} from "mobx-react";
import Menu from "react-metismenu";
import Button from "../../../utilities/Button";
import {ErrorBoundary} from "../../../utilities/ErrorBoundary";
import {StoreContext} from "../../../../stores/StoreLoader";
import {isSchoolBlocksApp, isSchoolFeedStandalone} from "../../../../utils/SchoolBlocksUtilities";
import NavigationLink from "../NavigationLink";
import HelpScout from "../../../support/HelpScout";
import styles from "./MobileNav.module.scss";
import {LogoutButton} from "../../../admin/sidebar/items/LogoutButton";
import FollowingButton from "../../../admin/sidebar/items/FollowingButton";
import ClickableLink from "../../../utilities/ClickableLink";
import {Shortcut} from "../../shortcuts/Shortcut";
import {IView} from "../../../../stores/InterfaceStore";

interface DefaultScrollLinkProps {
    children: JSX.Element | undefined,
    to: string,
}

export function DefaultScrollLink({children, to}: DefaultScrollLinkProps) {
    function handleOnClick() {
        const {interfaceStore} = useContext(StoreContext);
        interfaceStore.scrollToElement(`#${to}`)
    }

    const metislinkButtonClassName = classNames({
        [styles.metislinkButton]: true,
        ['metismenu-link']: true,
    });
    return <Button className={metislinkButtonClassName} onClick={handleOnClick}>
        {children}
    </Button>
}

export function DefaultLink({
    className,
    classNameActive,
    classNameHasActiveChild,
    active,
    hasActiveChild,
    to,
    externalLink,
    hasSubMenu,
    toggleSubMenu,
    activateMe,
    children,
    navigationObj,
    subMenuVisibility,
}) {
    const linkClassName = classNames(
        className,
        active && classNameActive,
        hasActiveChild && classNameHasActiveChild,
    )
    const {sidebarStore} = useContext(StoreContext);

    if (to === "/logout") {
        return <LogoutButton className={linkClassName}>
            {children}
        </LogoutButton>
    }

    if (to === "/following") {
        return <FollowingButton className={linkClassName}>
            {children}
        </FollowingButton>
    }

    return <NavigationLink
        navigationObj={navigationObj ? navigationObj : {}} // static links like Login won't have a navigationObj defined
        className={linkClassName}
        href={to}
        onClick={hasSubMenu ? toggleSubMenu : activateMe}
        target={externalLink ? '_blank' : undefined}
        aria-expanded={hasSubMenu ? subMenuVisibility : undefined}
        aria-haspopup={hasSubMenu}
    >
        {children}
    </NavigationLink>
}

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

function isExternalLink(navigationObj) {
    return isSchoolBlocksApp()
        ? true
        : typeof navigationObj.external_url === 'string' && navigationObj.external_url.length > 0;
}

export const navigationContent = (organizationStore) => {

    let navTree: Array<IMenuItem> = [];
    if (organizationStore.navigation.length > 0) {
        navTree = organizationStore.navigationTree
            .filter(item => item.enabled)
            .map(item => {
                const children = item.children
                    .filter(item => item.enabled)
                    .map(item => {
                        const grandchildren = item.children
                            .filter(child => child.enabled)
                            .map(child => {
                                return {
                                    icon: "empty-icon",
                                    label: child.title,
                                    externalUrl: isExternalLink(child),
                                    id: child.id,
                                    navigationObj: child,
                                };
                            });
                        const settings: IMenuItem = {
                            icon: item.icon,
                            label: item.title,
                            id: item.id,
                            externalUrl: isExternalLink(item),
                            navigationObj: item,
                        };
                        if (grandchildren && grandchildren.length > 0) {
                            settings.content = grandchildren;
                        }
                        return settings;
                    });
                const settings: IMenuItem = {
                    icon: item.icon || "",
                    label: item.title,
                    externalUrl: isExternalLink(item),
                    id: item.id,
                    navigationObj: item,

                };
                if (children && children.length > 0) {
                    settings.content = children;
                }
                return settings;
            });
    }

    return navTree;
}

const MobileNav = observer((props: MobileNavProps) => {
    const {organizationStore, userStore, interfaceStore} = useContext(StoreContext);
    const {isOpen, toggleOpen, identifier} = props;
    const contentFunction = () => props.content;
    const [content, setContent] = useState(contentFunction());
    const [incomingFocusElement, setIncomingFocusElement] = useState<Element | null>(null);
    const mobileMenuContainerClassName = classNames({
        [styles.mobileMenuContainer]: true,
    });

    function areAllIconsEmpty(array) {
        return array.every(item => item.icon === '');
    }

    const iconStateNameHidden = classNames({
        "fas": true,
        "fa-chevron-right": true,
        [styles.stateIcon]: true,
    });
    const iconStateNameVisible = classNames({
        "fas": true,
        "fa-chevron-down": true,
        [styles.stateIcon]: true,
    });
    const metisMenuIconClassName = classNames({
        [styles.metisMenuIcon]: true,
    });
    const shortcutListClassName = classNames({
        [styles.shortcutList]: true,
        [styles.shortcutListFourItems]: organizationStore.currentOrganization.shortcuts?.length === 4,
    });
    const menuContainerClassName = classNames({
        [styles.menuContainer]: true,
        [styles.menuContainerIconAllEmpty]: areAllIconsEmpty(content),
    });

    const handleKeydown = (evt) => {
        if (evt.code === "Escape") {
            handleClose();
        }
    }
    const handleClose = () => {
        if (isOpen) toggleOpen();
    }
    const focusCloseButton = () => {
        const closeButton = document.getElementById(identifier) as HTMLButtonElement;
        if (closeButton) closeButton.focus();
    }

    useEffect(() => {
        setContent(contentFunction());
    }, [organizationStore.navigation, userStore.user, userStore.id])

    useEffect(() => {
        if (isOpen) {
            setIncomingFocusElement(document.activeElement);
            focusCloseButton();
        } else if (!isOpen && incomingFocusElement) (incomingFocusElement as HTMLLinkElement).focus();

        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        }
    }, [isOpen]);

    useEffect(() => {
        const dropdownTop: HTMLElement | null = document.getElementById('exitContainer')
        if (dropdownTop) {
            dropdownTop?.focus();
            dropdownTop?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    },[isOpen])

    return (
        <ErrorBoundary>
            <span>
                <div className={mobileMenuContainerClassName}>
                    <CSSTransition in={isOpen} classNames={transitionClassNames} timeout={0}>
                        <nav className={styles.navContainer} role={'navigation'}>
                            {isOpen && <div className={styles.exitContainer} id={'exitContainer'}>
                                {props.identifier === 'nav-id' && <ClickableLink className={styles.search} href={'/search'}>
                                    <span className={"fas fa-search"}/>
                                    <span>Search</span>
                                </ClickableLink>}
                                <Button className={styles.mobileClose}
                                        aria-label={'Close Navigation'}
                                        onClick={handleClose}
                                        id={identifier}
                                >
                                    <span>Close</span>
                                    <span className={"fas fa-times"}/>
                                </Button>
                            </div>}
                            {isOpen && <Menu
                                // changing the key on open/close ensures the menu re-renders and closes all open items.
                                key={identifier + isOpen}
                                className={menuContainerClassName}
                                classNameContainerVisible={styles.menuContainerVisible}
                                classNameItem={styles.menuItem}
                                classNameItemHasVisibleChild={styles.hasVisibleChild}
                                classNameLink={styles.link}
                                classNameLinkActive={styles.activeLink}
                                classNameLinkHasActiveChild={styles.activeLinkChild}
                                content={content}
                                classNameIcon={metisMenuIconClassName}
                                iconNameStateHidden={iconStateNameHidden}
                                iconNameStateVisible={iconStateNameVisible}
                                iconNamePrefix={"fa "}
                                LinkComponent={organizationStore.navigation.length > 0 ? DefaultLink : DefaultScrollLink}
                            />}
                            {interfaceStore.view === IView.DEFAULT &&
                                organizationStore.currentOrganization.shortcuts?.length > 0 &&
                                props.identifier === 'nav-id' &&
                                    <ul className={shortcutListClassName}>
                                        {organizationStore.currentOrganization.shortcuts.map((shortcut) => {
                                            return <Shortcut key={shortcut.id}
                                                             shortcut={shortcut}
                                                             canEdit={false}
                                                          />
                                        })}
                                    </ul>}
                            <span tabIndex={0}
                                  onFocus={focusCloseButton}
                            />
                        </nav>
                    </CSSTransition>
                    <HelpScout/>
                </div>
            </span>
        </ErrorBoundary>
    );
})

interface MobileNavProps {
    isOpen: boolean,
    toggleOpen: () => void,
    identifier: string,
    content: Array<IMenuItem>,
}

export default MobileNav;
