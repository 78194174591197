import { useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import styles from "./styles/PoweredByCS.module.scss";
import ClickableLink from "../utilities/ClickableLink";
import Button from "../utilities/Button";
import { StoreContext } from "../../stores/StoreLoader";
import { LogoutButton } from "../admin/sidebar/items/LogoutButton";
import {isSchoolBlocksApp} from "../../utils/SchoolBlocksUtilities";

const poweredByContainerClassName = classNames({
    [styles.poweredByContainer]: true,
});
const loginButtonClassName = classNames({
    [styles.loginButton]: true,
});

const PoweredBy = observer(props => {
    const {modalStore, userStore, organizationStore} = useContext(StoreContext);

    function openLogin() {
        modalStore.addModal({
            type: "login",
            size: "lg",
            id: "login-modal",
        });
    }

    let poweredBy;
    if (isSchoolBlocksApp()) {
        poweredBy = <>
            <ClickableLink href={"https://www.schoolblocks.com"}>SchoolBlocks</ClickableLink>&nbsp;and&nbsp;
            <ClickableLink href={"https://www.schoolfeed.org"}>SchoolFeed</ClickableLink>
        </>;
    } else if (organizationStore.isSchoolFeedBrand) {
        poweredBy = <ClickableLink href={"https://www.schoolfeed.org"}>SchoolFeed</ClickableLink>;
    } else {
        poweredBy = <ClickableLink href={"https://www.cleverfeed.com"}>CleverFeed</ClickableLink>;
    }

    return (
        <div className={poweredByContainerClassName}>
            <div>
                {userStore.id ? <LogoutButton className={loginButtonClassName}>Logout</LogoutButton>
                    : <Button className={loginButtonClassName} onClick={openLogin}>Login</Button>}
            </div>
            <small>This website is powered by <span>
                {poweredBy}
            </span></small>
        </div>
    )
});

export default PoweredBy;
