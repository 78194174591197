import Button from "../../../utilities/Button";
import {useContext} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";

export function LogoutButton(props) {
    const {userStore, i18nStore} = useContext(StoreContext);

    return <Button onClick={async () => {
        await userStore.logout();
    }} {...props}>{props.children}</Button>
}
